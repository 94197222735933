// React imports
import React, { useState } from 'react';

// UI – style imports
import { WithStyles, withStyles, useMediaQuery, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

// local imports
import { styles } from './FinantialStatements.style';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';

const quartersSource = {
    quarters: [
        {
            label: 'Q3 2021',
            value: 1,
        },
        {
            label: 'Q4 2021',
            value: 2,
        },
        {
            label: 'Q1 2022',
            value: 3,
        },
        {
            label: 'Q2 2022',
            value: 4,
        },
        {
            label: 'Q3 2022',
            value: 5,
        },
        {
            label: 'Q4 2022',
            value: 6,
        },
        {
            label: 'Q1 2023',
            value: 7,
        },
        {
            label: 'Q2 2023',
            value: 8,
        },
        {
            label: 'Q3 2023',
            value: 9,
        },
        {
            label: 'Q4 2023',
            value: 10,
        },
        {
            label: 'Q1 2024',
            value: 11,
        },
        {
            label: 'Q2 2024',
            value: 12,
        },
        {
            label: 'Q3 2024',
            value: 13,
        },
        {
            label: 'Q4 2024',
            value: 14,
        },
    ],
};

function FinantialStatements(props: WithStyles<typeof styles>) {
    const { classes } = props;
    const { quarters } = quartersSource;

    const theme = useTheme();
    const [quarterString, setQuarterString] = useState('');

    const download = () => {
        if (!quarterString) return;
        var link = document.createElement('a');
        var name = quarterString.replace(/\s/g, '') + '.pdf';
        link.download = name;
        link.href = '/financial-documents/' + name;
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    return (
        <div>
            <Grid container className={classes.subGridTitle}>
                <Grid item xs={12}>
                    <Box className={classes.titleContainer}>
                        <p className={classes.title}>ESTADOS</p>
                        <p className={classes.title}>FINANCIEROS</p>
                    </Box>
                    <Box className={classes.calcContainer}>
                        <Box className={classes.firstRow}>
                            <Box className={classes.textContainer}>
                                <p className="text">Descarga y consulta nuestros estados financieros disponibles:</p>
                            </Box>
                            <Dropdown
                                name="quarters"
                                title="Seleccionar..."
                                list={quarters}
                                onChange={item => setQuarterString(item.label)}
                            />
                            <Button className={classes.downloadButton} text="Descargar" onClick={download} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
}

export default withStyles(styles)(FinantialStatements);
